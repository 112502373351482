import axios from 'axios'

const API = 'landing/sections'

const ENDPOINTS = {
    postTitle(model){
        return axios.post(`${API}/save/title`, model)
    },
    updateTitle(idSection, model){
        return axios.put(`${API}/${idSection}/update/title`, model)
    },
    getElementsSection(idSection){
        return axios.get(`${API}/${idSection}/elements`)
    },
    getElementSection(idElement){
        return axios.get(`${API}/${idElement}/element`)
    },
    postSection(model){
        return axios.post(`${API}/save/image/section`, model)
    },
    updateSection(idSection, model){
        return axios.put(`${API}/${idSection}/update/section`, model)
    },
    postImage(model){
        return axios.post(`${API}/save/image`, model)
    },
    updateOrdenImages(idSection, model){
        return axios.put(`${API}/${idSection}/update/image/order`, model);
    },
    getSearchEnlace(params){
        return axios.get(`${API}/query/enlaces`, {params})
    },
    putElementSection(idElementSection, model){
        return axios.put(`${API}/${idElementSection}/update/element`, model)
    },
    deleteImage(idImage){
        return axios.delete(`${API}/${idImage}/image/delete`)
    },
    changeStateElement(idImage, model){
        return axios.put(`${API}/${idImage}/element/change/state`, model)
    },
    deleteEnlace(idImage){
        return axios.delete(`${API}/${idImage}/enlace/delete`)
    },
    deleteSection(idSection){
        return axios.delete(`${API}/${idSection}/delete`)
    },
    changeStateSection(model, idSection){
        return axios.put(`${API}/${idSection}/change/state`, model)
    },
    ordenarSecciones(model, idLanding){
        return axios.put(`${API}/${idLanding}/update/orden`, model)
    },
    putDiasHabiles(idElementSection, model){
        return axios.put(`${API}/${idElementSection}/update/element/dias-habiles`, model)
    },
}

export default ENDPOINTS;