<template>
    <modal ref="modalEnlace" titulo="Enlace" no-aceptar adicional="Guardar" tamano="modal-xl" @adicional="putElementSection" @cancelar="goBack">
        <ValidationObserver ref="validacion">
            <div class="d-middle-center mx-0 bg-fondo w-100 position-absolute header-modal">
                <div class="col-3 d-middle h-100 pl-5">
                    <span class="f-12 mr-2">Enlace</span>
                    <el-select v-model="enlace" size="small" :clearable="true" @clear="clearEnlace" @change="changeEnlace">
                        <el-option
                        v-for="item in enlaces"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-4 px-3">
                    <el-select
                    v-model="optionSelect"
                    :disabled="enlace == 4 || enlace == 5 || enlace == 6 || enlace == 7"
                    class="w-100"
                    size="small"
                    prefix-icon="icon-search"
                    :multiple="false"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Buscar"
                    :remote-method="searchEnlace"
                    :loading="loading"	
                    >
                        <el-option
                        v-for="item in resultsQueryList"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <button type="button" class="btn-red" @click="addOption">
                    <el-tooltip content="Añadir enlace" placement="bottom" effect="light">
                        <i class="icon-plus f-15" />
                    </el-tooltip>
                </button>
            </div>
            <div class="row mx-0 py-4" />
            <div class="row mx-0 justify-center pt-4 pl-5">
                <div class="col">
                    <div v-if="showOption" class="row mx-0 align-items-center mb-4">
                        <template v-if="enlace == 2">
                            <i class="icon-product f-20 text-general" />
                            <div class="col-auto text-general">
                                <span class="f-500">Producto:</span>
                            </div>
                    
                            <img :src="model.optionsSelect.imagen" class="br-8 border" width="62" height="62" />
                            <div class="col-5 px-2">
                                <p class="nombre-producto text-general">
                                    {{ model.optionsSelect.nombre }}
                                </p>
                        
                                <p v-if="enlace == 2" class="text-grey f-10">
                                    {{ model.optionsSelect.presentacion }}
                                </p>
                            </div>
                        </template>
                        <template v-else>
                            <i :class="iconEnlace()" class="f-20 text-general" />
                            <div class="col-auto text-general">
                                <span class="f-500">{{ nameEnlace() }}</span>
                                <span class="mx-2">{{ model.optionsSelect.nombre }}</span>
                            </div>
                            <div v-if="enlace == 7" class="col-5">
                                <ValidationProvider v-slot="{errors}" rules="required|max:120" name="Personalizado">
                                    <el-input v-model="personalizado" class="w-100" size="small" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                        </template>
                        <i class="icon-cancel f-20 text-general cr-pointer" @click="deleteOptionSelected" />
                    </div>
                </div>
                <div class="col">
                    <div v-if="enlace != 2 && enlace != 6 && enlace != 7" class="row mx-0 justify-center">
                        <el-checkbox v-model="model.showWindow" class="check-green mt-1" />
                        <div class="col-auto px-2">
                            <span class="mx-2 text-general">Abrir en otra pestaña</span>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/landing/landing_sections'
export default {
    data(){
        return {
            showOption: false,
            loading: false,
            resultsQuery: [],
            resultsQueryList: [],
            model: {
                showWindow: false,
                optionsSelect: {},
            },
            idSection: null,
            idElementSection: null,
            optionSelect: null,
            enlace: null,
            personalizado: null,
            abrirVentana: false,
            urlProduct: 'https://d1.com.co/wp-content/uploads/12000381.jpg'
        }
    },
    computed:{
        enlaces(){
            return [
                { id:1, label:'Categoría', icon:'icon-category', column:'id_categoria', redirect: 13 },
                { id:2, label:'Producto', icon:'icon-product', column:'id_producto', redirect: 12 },
                { id:3, label:'Promoción', icon:'icon-icon-sale-outline', column:'id_promocion', redirect: 11 },
                { id:4, label:'Recompensas', icon:'icon-diamond', column: 'redireccion', redirect: 21 },
                { id:5, label:'Premios', icon:'icon-gift-outline', column: 'redireccion', redirect: 22 },
                { id:6, label:'Cupones', icon:'icon-cupon', column: 'redireccion', redirect: 23 },
                { id:7, label:'Personalizado', icon:'icon-link', column: 'link_personalizado', redirect: 24 },
            ]
        }
    },
    methods: {
        toggle(idSection, idElementSection, bandera){
            this.clearAll();
            this.idSection = idSection;
            this.idElementSection = idElementSection;
            if(bandera === 'editar'){
                this.getElementSection();
            }
            this.$refs.modalEnlace.toggle();
        },

        async getElementSection(){
            try {
                const {data} = await Service.getElementSection(this.idElementSection);

                if(_.isNull(data.type)){
                    return;
                }

                switch(parseInt(data.type)){
                case 11:
                    this.enlace = 3;
                    await this.goSearch({enlace:this.enlace, query: null});
                    this.model.optionsSelect = data.element;
                    break;
                case 12:
                    this.enlace = 2;
                    this.model.optionsSelect = data.element;
                    break;
                case 13:
                    this.enlace = 1;
                    await this.goSearch({enlace:this.enlace, query: null});
                    this.model.optionsSelect = data.element;
                    break;
                case 21:
                    this.enlace = 4;
                    this.model.optionsSelect.id = 21;
                    break;
                case 22:
                    this.enlace = 5;
                    this.model.optionsSelect.id = 22;
                    break;
                case 23:
                    this.enlace = 6;
                    this.model.optionsSelect.id = 22;
                    break;
                case 24:
                    this.enlace = 7;
                    this.personalizado = data.element.nombre;
                    break;
                }

                this.model.showWindow = parseInt(data.nueva_pestana) === 1 ? true : false;
                this.showOption = true;
            } catch(e){
                this.error_catch(e)
            }
        },

        async putElementSection(){
            try {
                if(parseInt(this.enlace) != 7){
                    if(_.isEmpty(this.model.optionsSelect))return;
                } else {
                    const valid = await this.$refs.validacion.validate();
                    if(!valid)return;
                }

                const model = {
                    showWindow: this.model.showWindow == true ? 1 : 0,
                    column: this.enlaces.find((item) => parseInt(item.id) === parseInt(this.enlace)).column,
                    redirect: this.enlaces.find((item) => parseInt(item.id) === parseInt(this.enlace)).redirect,
                    value: parseInt(this.enlace) != 7 ? this.model.optionsSelect.id : this.personalizado,
                };

                if (this.enlace == 7) model.showWindow = 1;

                const {data} = await Service.putElementSection(this.idElementSection, model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                
                this.$refs.modalEnlace.toggle();
                this.$emit('redirectImages', this.idSection);
            } catch(e){
                this.error_catch(e)
            }
        },

        changeEnlace(){
            this.showOption = false;
            this.resultsQuery = [];
            this.resultsQueryList = [];
            this.model.optionsSelect = {};
            this.optionSelect = null;

            switch(parseInt(this.enlace)){
            case 1:
                return this.goSearch({enlace:this.enlace, query: null});
            case 3:
                return this.goSearch({enlace:this.enlace, query: null});
            case 4:
                this.model.optionsSelect.id = 21;
                return this.showOption = true;
            case 5:
                this.model.optionsSelect.id = 22;
                return this.showOption = true;
            case 6:
                this.model.optionsSelect.id = 23;
                return this.showOption = true;
            case 7:
                return this.showOption = true;
            }
        },

        async searchEnlace(query){
            if(!this.enlace)return;
            // Query for product
            if(parseInt(this.enlace) === 2){
                const search = async() => {

                    if(query.length == 0){
                        this.resultsQuery = [];
                        this.resultsQueryList = [];
                    }

                    if(query.length < 3)return;

                    const model = {
                        enlace: this.enlace,
                        query: query
                    };

                    await this.goSearch(model);
                }

                await this.delay(search);
            }
            // Query for category or promotion
            if(parseInt(this.enlace) === 1 || parseInt(this.enlace) === 3){
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.resultsQueryList = this.resultsQuery.filter(item => {
                        return item.nombre.toLowerCase()
                            .indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            }
        },

        async goSearch(model){
            try {
                this.loading = true;

                const {data} = await Service.getSearchEnlace(model)
                
                this.resultsQuery = data.results;
                this.resultsQueryList = data.results;
            } catch(e){
                this.error_catch(e)
            } finally{
                this.loading = false;
            }
        },

        addOption(){
            if(!this.optionSelect)return;

            this.model.optionsSelect = this.resultsQuery.find((item) => item.id === this.optionSelect);
            this.showOption = true;
        },

        clearEnlace(){
            this.showOption = false;
            this.resultsQuery = [];
            this.resultsQueryList = [];
            this.model.optionsSelect = {};
            this.optionSelect = null;
            this.enlace = null;
        },

        deleteOptionSelected(){
            this.showOption = false;
            this.model.optionsSelect = {};
            this.optionSelect = null;
            this.personalizado = null;

            if(parseInt(this.enlace) === 2){
                this.resultsQuery = [];
                this.resultsQueryList = [];
            }

            if(parseInt(this.enlace) != 2 && parseInt(this.enlace) != 1 && parseInt(this.enlace) != 3){
                this.enlace = null;
            }
        },

        nameEnlace(){
            return this.enlaces.find((item) => parseInt(item.id) === parseInt(this.enlace)).label;
        },

        iconEnlace(){
            return this.enlaces.find((item) => parseInt(item.id) === parseInt(this.enlace)).icon;
        },

        clearAll(){
            this.model.showWindow = false;
            this.clearEnlace();
        },

        goBack(){
            this.$emit('redirectImages', this.idSection);
        },
    }
}
</script>
<style lang="scss" scoped>
.header-modal{
    height: 53px;
    left: 0px;
}

.nombre-producto{
    height: 46px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 19px;
}
</style>